<template>
  <section>
    <b-row>
      <b-col>
        <h1 class="title">Community Update</h1>
      </b-col>
    </b-row>
    <b-row class="options-container">
      <b-col md="12">
        <b-card class="option-card banner">
          <div>
            <h2 class="option-title">
              🚀 Something Big is Coming – EssayGrader 3.0!
            </h2>
            <div class="option-description">
              <p>
                We’ve been hard at work on the next evolution of EssayGrader,
                and we can’t wait to share it with you! Version 3.0 brings a
                fresh look, smarter AI, and powerful new tools to make grading
                even more intuitive.
              </p>
              <p>
                Get ready for
                <b>
                  student writing performance insights, Class performance
                  insights, plagiarism detection (separate from AI detection),
                  and brand new grading and feedback features
                </b>
                —all designed to give you more control and efficiency in your
                essay grading workflow.
              </p>
              <p>Stay tuned—more details dropping this week! 👀✨</p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: "CommunityUpdate",
};
</script>

<style scoped>
.title {
  color: #000;
  font: 500 32px/119% Roboto, sans-serif;
  margin-left: 16px;
  position: relative;
}

.options-container {
  position: relative;
  margin: 25px 0 0;
}

.option-card {
  height: 100%;
}

.option-title {
  color: #000;
  font: 500 20px/120% Roboto, sans-serif;
  margin-bottom: 12px;
}

.option-icon {
  width: 32px;
  height: 32px;
}

.option-description {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.register-now {
  color: hsla(257, 61%, 56%, 1);
  padding: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.banner {
  background-image: url("@/assets/banner-top.png");
  background-size: calc(100% + 2px) 8px;
  background-position: -1px -1px;
  background-repeat: no-repeat;

  .card-body {
    display: flex;

    .option-description {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      ul {
        padding-left: 20px;
      }
    }

    .register-now {
      color: hsla(257, 61%, 56%, 1);
      padding: 0;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .text-additional {
      font-size: 12px;
      font-style: italic;
      line-height: 24px;
      color: hsla(210, 11%, 15%, 1);
    }
  }
}
</style>
