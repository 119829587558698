<script>
export default {
  name: "FreeToolsWrapper",
  data() {
    return {
      messagePort: null,
      lastHeightUpdate: null,
    };
  },
  methods: {
    notifyResize: function () {
      const height = window.document.body.scrollHeight;
      if (this.lastHeightUpdate !== height) {
        this.messagePort?.postMessage({ height });
        this.lastHeightUpdate = height;
      }
    },

    handleMessage(e) {
      if (e.data === "notifyResize") {
        this.messagePort = e.ports[0];
        this.notifyResize();
      }
    },
  },
  beforeCreate() {
    window.$crisp.push(["do", "chat:hide"]);
  },
  mounted() {
    window.document.body.classList.add("tool-wrapper");

    window.addEventListener("resize", this.notifyResize);
    window.addEventListener("message", this.handleMessage, false);
  },
  beforeDestroy() {
    window.document.body.classList.remove("tool-wrapper");

    window.removeEventListener("resize", this.notifyResize);
    window.removeEventListener("message", this.handleMessage);
  },
};
</script>

<template>
  <div class="tool-wrapper">
    <slot />
  </div>
</template>

<style>
body.tool-wrapper {
  background-color: transparent !important;
}
</style>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.tool-wrapper {
  font-family: "Montserrat", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 16px;

  ::v-deep {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "Montserrat", serif;
    }
    b,
    .strong {
      font-weight: 500;
    }

    .outline {
      border: 1px solid #6a50f3;
      border-radius: 10px;
      color: #6a50f3;
      background: #ffffff;
      &:focus {
        text-decoration: none;
        box-shadow: none;
      }
    }

    .button {
      font-size: 16px;
      color: #ffffff;
      background: #6a50f3;
      border-radius: 10px;
      &:focus {
        text-decoration: none;
        box-shadow: none;
      }
    }
    .large {
      padding: 14px 28px;
    }

    .scroll-wrapper {
      overflow: auto;
      height: 100%;
    }

    .panel {
      border: 1px solid #e6e6e6;
      border-radius: 1rem;
      padding: 24px;
      background: #ffffff;
    }
  }
}
</style>
