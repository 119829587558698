<script>
import EgDropdown from "@/components/global/eg_dropdown.vue";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import { createTongueTwisters } from "@/api/requests/freeTools";
import FreeToolsWrapper from "@/views/free-tools/free-tools-wrapper.vue";

export default {
  name: "TongueTwister",
  components: { FreeToolsWrapper, EgDropdown, EgFormGroup },
  data() {
    return {
      loading: false,
      topics: ["Animals", "Colors", "Weather", "Food"],
      selectedTopic: "Animals",
      isCustomTopic: false,
      customTopic: null,
      gradeLevels: ["Elementary", "Middle School", "High School", "College"],
      selectedGradeLevel: "Elementary",
      difficulty: "easy",
      numberOfWords: 10,
      results: null,
    };
  },
  methods: {
    selectTopic(topic) {
      this.selectedTopic = topic;
      this.isCustomTopic = false;
    },
    async generate() {
      this.loading = true;
      try {
        const { data } = await createTongueTwisters({
          topic: this.isCustomTopic ? this.customTopic : this.selectedTopic,
          difficulty: this.difficulty,
          numberOfWords: this.numberOfWords,
          gradeLevel: this.selectedGradeLevel,
        });
        this.results = data.response;
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.freeToolsWrapper.notifyResize();
        });
      }
    },
  },
  watch: {
    selectedGradeLevel(v) {
      switch (v) {
        case "Elementary":
          this.difficulty = "easy";
          this.numberOfWords = 5;
          break;
        case "Middle School":
          this.difficulty = "easy";
          this.numberOfWords = 10;
          break;
        case "High School":
          this.difficulty = "medium";
          this.numberOfWords = 15;
          break;
        case "College":
          this.difficulty = "hard";
          this.numberOfWords = 20;
          break;
      }
    },
  },
};
</script>

<template>
  <FreeToolsWrapper ref="freeToolsWrapper">
    <eg-form-group label="Theme/Topic" required>
      <eg-dropdown :text="isCustomTopic ? 'Enter my own theme' : selectedTopic">
        <b-dropdown-item-button
          @click="selectTopic(topic)"
          v-for="topic in topics"
          :key="topic"
        >
          {{ topic }}
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="isCustomTopic = true">
          Enter my own theme
        </b-dropdown-item-button>
      </eg-dropdown>
      <b-form-input
        class="mt-3"
        placeholder="Topic"
        v-if="isCustomTopic"
        v-model="customTopic"
      />
    </eg-form-group>
    <eg-form-group label="Grade level">
      <eg-dropdown :text="selectedGradeLevel">
        <b-dropdown-item-button
          v-for="gradeLevel in gradeLevels"
          :key="gradeLevel"
          @click="selectedGradeLevel = gradeLevel"
        >
          {{ gradeLevel }}
        </b-dropdown-item-button>
      </eg-dropdown>
    </eg-form-group>
    <eg-form-group label="Difficulty level" required>
      <b-form-radio v-model="difficulty" name="difficulty" value="easy">
        Easy
      </b-form-radio>
      <b-form-radio v-model="difficulty" name="difficulty" value="medium">
        Medium
      </b-form-radio>
      <b-form-radio v-model="difficulty" name="difficulty" value="hard">
        Hard
      </b-form-radio>
    </eg-form-group>
    <eg-form-group label="Number of words" required>
      <eg-dropdown :text="numberOfWords.toString()">
        <b-dropdown-item-button @click="numberOfWords = 5">
          5
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="numberOfWords = 10">
          10
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="numberOfWords = 15">
          15
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="numberOfWords = 20">
          20
        </b-dropdown-item-button>
      </eg-dropdown>
    </eg-form-group>
    <div class="d-flex justify-content-center align-items-center">
      <b-button @click="generate" :disabled="loading" class="button large">
        <b-spinner v-show="loading" small />
        Generate Tongue Twisters
      </b-button>
    </div>

    <div v-if="results" class="panel mt-3">
      {{ results }}
    </div>
  </FreeToolsWrapper>
</template>

<style scoped lang="scss">
.panel {
  border: 1px solid #e6e6e6;
  border-radius: 1rem;
  padding: 24px;
  background: #ffffff;

  white-space: pre-line;
}
.button {
  font-size: 16px;
  color: #ffffff;
  background: #6a50f3;
  border-radius: 10px;
  &:focus {
    text-decoration: none;
    box-shadow: none;
  }
}

.large {
  padding: 14px 28px;
}
</style>
