<script>
import EgFormGroup from "@/components/global/eg_form_group.vue";
import { createLetterOfRecommendation } from "@/api/requests/freeTools";
import FreeToolsWrapper from "@/views/free-tools/free-tools-wrapper.vue";
import EgDropdown from "@/components/global/eg_dropdown.vue";

const pronounces = ["She/Her/Hers", "He/Him/His", "They/Them/Theirs"];

export default {
  name: "LetterOfRecommendation",
  components: {
    EgDropdown,
    FreeToolsWrapper,
    EgFormGroup,
  },
  data() {
    return {
      institution: "",
      name: "",
      pronounces,
      pronouns: pronounces[0],
      relationship: "",
      info: "",
      loading: false,
      letter: null,
    };
  },
  methods: {
    fill() {
      this.institution = "Metro State University";
      this.name = "Sara Jones";
      this.pronouns = pronounces[0];
      this.relationship = "Teacher";
      this.info = `
Earned All-A honor roll for 5 consecutive quarters
Won first place in school science fair for "Designing a Sustainable Water Purification System"
Completed advanced reading program and read 6 books beyond classroom assignment
`.trim();
    },
    async generate() {
      this.loading = true;
      try {
        this.letter = null;
        const { data } = await createLetterOfRecommendation({
          institution: this.institution,
          relationship: this.relationship,
          info: this.info,
          pronouns: this.pronouns,
          name: this.name,
        });
        this.letter = data.response;
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.freeToolsWrapper.notifyResize();
        });
      }
    },
  },
};
</script>

<template>
  <FreeToolsWrapper ref="freeToolsWrapper">
    <div>
      <h3>Letter of Recommendation</h3>
      <p>
        Generate a letter of recommendation to a university or institution for a
        student using specific content about them.
      </p>
      <eg-form-group label="Institution" required>
        <b-form-input
          v-model="institution"
          placeholder="Metro State University"
        />
      </eg-form-group>
      <eg-form-group label="Student name" required>
        <b-form-input v-model="name" placeholder="Student name" />
      </eg-form-group>
      <eg-form-group label="Student Pronouns" required>
        <eg-dropdown :text="pronouns">
          <b-dropdown-item-button
            v-for="p in pronounces"
            :key="p"
            @click="pronouns = p"
          >
            {{ p }}
          </b-dropdown-item-button>
        </eg-dropdown>
      </eg-form-group>
      <eg-form-group label="Relationship to Student" required>
        <b-form-input
          v-model="relationship"
          placeholder="Teacher, Counselor, Mentor"
        />
      </eg-form-group>
      <eg-form-group label="Important information in include" required>
        <b-textarea
          v-model="info"
          placeholder="List details about why you are recommending this student."
          :rows="5"
        />
      </eg-form-group>
      <div
        class="d-flex justify-content-center align-items-center flex-gap-1 mb-3"
      >
        <b-button @click="fill" :disabled="loading" class="large outline">
          Use example
        </b-button>
        <b-button @click="generate" :disabled="loading" class="button large">
          <b-spinner v-show="loading" small />
          Generate
        </b-button>
      </div>
    </div>
    <div v-if="letter">
      <div class="letter panel">
        {{ letter }}
      </div>
    </div>
  </FreeToolsWrapper>
</template>

<style scoped lang="scss">
.letter {
  white-space: pre-line;
}
</style>
