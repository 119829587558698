import api from "@/api/api";

export const createRubric = (payload) => {
  return api.post("/rubrics", payload);
};

export const getSystemRubrics = () => {
  return api.get("/rubrics", { params: { isSystem: true } });
};

export const getRecentRubrics = (limit = 2) => {
  return api.get("/rubrics/recent", { params: { limit } });
};

export const getRubrics = (isOwner = undefined) => {
  const params = {};
  if (isOwner !== undefined) {
    params.isOwner = isOwner;
  }
  return api.get("/rubrics", { params });
};

export const getSingleRubric = (rubricId) => {
  return api.get(`/rubrics/${rubricId}`);
};

export const getSingleRubricByShareSlug = (slug) => {
  return api.get(`/share-links/${slug}/entity`);
};

export const updateSingleRubric = (rubricId, payload) => {
  return api.put(`/rubrics/${rubricId}`, payload);
};

export const deleteSingleRubric = (rubricId) => {
  return api.delete(`/rubrics/${rubricId}`);
};

export const setShareRubricByDomain = (shareId, value) => {
  return api.patch(`/share-links/${shareId}`, { isSharedWithDomain: value });
};

export const generateRubricByAi = (payload) => {
  return api.put("/rubrics/ai-generated", payload, {
    baseURL: process.env.VUE_APP_EG_TOOLS_URL,
  });
};

export const generateRubricByAiByUpload = (payload) => {
  return api.put("/rubrics/ai-generated/by-upload", payload, {
    baseURL: process.env.VUE_APP_EG_TOOLS_URL,
  });
};
