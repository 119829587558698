<template>
  <div class="mt-5">
    <h1>Import from {{ LMS_PROVIDERS[platform]?.label }}</h1>
    <b-card class="mt-5 py-1">
      <eg-stepper
        v-model="loading"
        finish-label="Grade"
        :stepsTitles="stepsTitles"
        @beforeChange="beforeChange"
        @finish="finishHandler"
      >
        <template #slot-1>
          <import-select-class
            v-model="selectedClassIds"
            :bulk="bulk"
            @loaded="loading = false"
          />
        </template>
        <template #slot-2>
          <import-select-assignment
            v-model="selectedAssignments"
            :class-ids="selectedClassIds"
            :bulk="bulk"
            @loaded="loading = false"
            @setAssignmentDefaultRubricId="setAssignmentDefaultRubricIdHandler"
          />
        </template>
        <template v-if="!bulk" #slot-3>
          <import-select-submissions
            v-model="selectedSubmissionsIds"
            :class-id="selectedClassIds[0]"
            :assignment-id="selectedAssignments[0]?.id"
            :bulk="bulk"
            @loaded="loading = false"
          />
        </template>
        <template #slot-4>
          <import-bulk-select-rubric
            v-if="bulk"
            ref="importBulkSelectRubric"
            v-model="selectedAssignments"
          />
          <import-select-rubric
            v-else
            ref="importSelectRubric"
            v-model="selectedRubric"
          />
        </template>
        <template v-if="!bulk" #slot-5>
          <import-select-details
            ref="importSelectDetails"
            show-intensity
            :selectedRubric="selectedRubric"
            :selected-language-id="selectedLanguageId"
            :rubricIntensity="rubricIntensity"
            :selectedClassId="selectedClassIds[0]"
            :selectedAssignmentId="selectedAssignments[0]?.id"
            :platform="LMS_PROVIDERS[platform]"
            @input-language="selectedLanguageId = $event"
            @input-intensity="rubricIntensity = $event"
            @input-assignmentId="draftAssignmentId = $event"
          />
        </template>
      </eg-stepper>
    </b-card>
  </div>
</template>

<script>
import { getSingleRubric } from "@/api";
import importApi from "@/api/requests/import";
import EgStepper from "@/components/global/eg_stepper.vue";
import ImportSelectClass from "./imports_select_class.vue";
import ImportSelectAssignment from "./imports_select_assignment.vue";
import ImportSelectSubmissions from "./imports_select_submissions.vue";
import ImportBulkSelectRubric from "./imports_bulk_select_rubric.vue";
import ImportSelectRubric from "./imports_select_rubric.vue";
import ImportSelectDetails from "./imports_select_details.vue";
import errorHandler from "./errorHandler";
import { LMS_PROVIDERS } from "@/constants";

export default {
  name: "ImportWizard",

  components: {
    EgStepper,
    ImportSelectClass,
    ImportSelectAssignment,
    ImportSelectSubmissions,
    ImportSelectRubric,
    ImportBulkSelectRubric,
    ImportSelectDetails,
  },

  props: {
    platform: {
      type: String,
      required: true,
      validate: (value) => Object.keys(LMS_PROVIDERS).includes(value),
    },

    bulk: {
      type: Boolean,
      default: false,
    },
  },

  inject: ["serviceAccount", "serviceName"],

  data() {
    return {
      LMS_PROVIDERS,
      loading: false,
      selectedClassIds: [],
      selectedAssignments: [],
      selectedSubmissionsIds: [],
      selectedRubric: null,
      selectedLanguageId: null,
      rubricIntensity: "moderate",
      draftAssignmentId: null,
    };
  },

  computed: {
    stepsTitles() {
      if (this.bulk) {
        return [
          "Step one: Select a class",
          "Step two: Select assignment",
          "Step three: Select rubric",
        ];
      }

      return [
        "Step one: Select a class",
        "Step two: Select assignment",
        "Step three: Select submissions",
        "Step four: Select rubric",
        "Step five: Grading details",
      ];
    },
    selectedRubricId() {
      return this.selectedRubric?.id;
    },
  },

  methods: {
    async setAssignmentDefaultRubricIdHandler(rubricIds) {
      if (rubricIds.length === 0) return;

      const { data } = await getSingleRubric(rubricIds[0]);
      this.selectedRubric = data;
    },

    async beforeChange({ index, resolve, reject }) {
      switch (index) {
        case 0: {
          if (this.selectedClassIds.length === 0) {
            reject();
            this.$showToastError("Please select a class", {
              title: "Validation Error",
            });
          } else {
            resolve();
          }
          break;
        }
        case 1:
          if (this.selectedAssignments.length === 0) {
            reject();
            this.$showToastError("Please select an assignment", {
              title: "Validation Error",
            });
          } else {
            resolve();
          }
          break;
        case 2:
          if (this.bulk) {
            const isDone = await this.$refs.importBulkSelectRubric.validate();

            if (isDone) {
              resolve();
            } else {
              reject();
              this.$showToastError(
                "Please select a rubric for each asignments",
                {
                  title: "Validation Error",
                }
              );
            }
          } else {
            if (this.selectedSubmissionsIds.length === 0) {
              reject();
              this.$showToastError("Please select submissions", {
                title: "Validation Error",
              });
            } else {
              resolve();
            }
          }
          break;

        case 3: {
          const isDone = await this.$refs.importSelectRubric.validate();

          if (isDone) {
            resolve();
          } else {
            reject();
            this.$showToastError("Please select a rubric", {
              title: "Validation Error",
            });
          }

          break;
        }

        case 4: {
          const isDone = await this.$refs.importSelectDetails.validate();

          if (isDone) {
            resolve();
          } else {
            reject();
            this.$showToastError(
              "There was an error updating the google assignment, please try again.",
              {
                title: "Validation Error",
              }
            );
          }

          break;
        }

        default:
          resolve();
      }
    },

    async finishHandler(resolve) {
      if (this.bulk) {
        const payload = {
          tasks: this.selectedAssignments.map((assignment) => ({
            classId: assignment.courseId,
            assignmentId: assignment.id,
            rubricId: assignment.rubric.id,
          })),
        };

        try {
          await importApi[this.serviceName].bulkImport(
            this.serviceAccount.id,
            payload
          );
          this.$root.$bvToast.toast(
            `Imports successfully added to queued for grading.`,
            {
              title: "Import successful",
              variant: "success",
            }
          );
          resolve();
          this.$router.push({ name: "essays" });
          return;
        } catch (error) {
          errorHandler({
            vm: this,
            error,
            title: "Import Error",
            message:
              "There was a problem import submissions from the classroom. Please try again",
          });
          return resolve();
        }
      }

      const payload = {
        submissionsIds: this.selectedSubmissionsIds.map((id) => `${id}`), // convert to sting
        intensity: this.rubricIntensity,
        LanguageId: this.selectedLanguageId,
      };

      if (this.selectedRubric.gradeLevel && this.selectedRubric.essayType) {
        payload.gradeLevel = this.selectedRubric.gradeLevel;
        payload.gradeScale = "letter";
        payload.type = this.selectedRubric.essayType;
      } else {
        payload.RubricId = this.selectedRubricId;
      }

      if (this.draftAssignmentId) {
        payload.AssignmentId = this.draftAssignmentId;
      }

      try {
        const { data } = await importApi[this.serviceName].importSubmissions(
          this.serviceAccount.id,
          this.selectedClassIds[0],
          this.selectedAssignments[0].id,
          payload
        );

        const notImported = data.filter((item) => !item.imported);

        if (notImported.length > 0) {
          let message = "Submissions were skipped for the following students";

          if (this.platform === "google") {
            message +=
              "because they were not in the Google Docs format. More formats will be supported at a future date";
          }

          this.$showToastError(
            `${message}.
            Students with skipped essays:
            ${notImported.map((item) => item.name).join(", ")}
            `,
            {
              title: "Some submissions skipped",
              noAutoHide: true,
            }
          );
        } else {
          this.$root.$bvToast.toast(
            `Student assignments were imported successfully and are queued for grading.`,
            {
              title: "Import successful",
              variant: "success",
            }
          );
        }

        resolve();
        this.$router.push({ name: "essays" });
      } catch (error) {
        errorHandler({
          vm: this,
          error,
          title: "Import Error",
          message:
            "There was a problem import submissions from the classroom. Please try again",
        });
        resolve();
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  padding-left: 0;
  padding-right: 0;
}

.export-to-gc {
  margin: 0 12px;
}
</style>
