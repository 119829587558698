<script>
import FreeToolsWrapper from "@/views/free-tools/free-tools-wrapper.vue";

export default {
  name: "RoiCalculator",
  components: {
    FreeToolsWrapper,
  },
  data() {
    return {
      essaysPerMonth: 30,
      essaysLength: 1,
      gradingTime: 20,
      gradingTimeInEssayGrader: 2,
    };
  },
  computed: {
    minutesSaved() {
      return Math.max(
        0,
        (this.gradingTime - this.gradingTimeInEssayGrader) * this.essaysPerMonth
      );
    },
    savedFormatted() {
      const minutes = this.minutesSaved % 60;
      const hours = (this.minutesSaved - minutes) / 60;
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
    },
  },
};
</script>

<template>
  <FreeToolsWrapper>
    <div class="control">
      <span>Number of Essays Graded Per Month</span>
      <b-form-input number type="number" v-model="essaysPerMonth" />
      <b-form-input
        id="range-2"
        v-model="essaysPerMonth"
        type="range"
        min="10"
        max="200"
        step="5"
      />
    </div>

    <div class="control">
      <span>Average Essay Length (in pages)</span>
      <b-form-input number type="number" v-model="essaysLength" />
      <b-form-input
        id="range-2"
        v-model="essaysLength"
        type="range"
        min="1"
        max="10"
        step="0.5"
      />
    </div>

    <div class="control">
      <span>Grading Time Per Essay (in minutes)</span>
      <b-form-input number type="number" v-model="gradingTime" />
      <b-form-input
        id="range-2"
        v-model="gradingTime"
        type="range"
        min="1"
        max="60"
        step="1"
      />
    </div>
    <div class="results">
      <div class="panel">
        <p class="header"><span class="icon">⏱️</span> Time saved</p>
        <p class="value">
          {{ savedFormatted }}
        </p>
        <p class="subheader">monthly</p>
      </div>

      <div class="panel">
        <p class="header"><span class="icon">💵</span> Money saved</p>
        <p class="value">
          ${{ (essaysLength * essaysPerMonth * 0.05).toFixed(2) }}
        </p>
        <p class="subheader">on printing</p>
      </div>

      <div class="panel">
        <p class="header"><span class="icon">📚</span> Paper saved</p>
        <p class="value">
          {{ Math.ceil(essaysLength * essaysPerMonth) }}
        </p>
        <p class="subheader">sheets of paper</p>
      </div>

      <div class="panel">
        <p class="header"><span class="icon">🌱</span> Trees saved</p>
        <p class="value">
          {{ (((essaysLength * essaysPerMonth) / 10000) * 12).toFixed(3) }}
        </p>
        <p class="subheader">yearly</p>
      </div>
    </div>
  </FreeToolsWrapper>
</template>

<style scoped lang="scss">
.panel {
  background-repeat: no-repeat !important;
  background-position: -15px 15px !important;

  p {
    margin-bottom: 0;
  }

  .header {
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    & .icon {
      font-size: 1.5rem;
    }
  }
  .value {
    font-size: 1.8em;
    line-height: 1.2;
    padding: 0.5rem 0;
  }
  .subheader {
  }
}

.results {
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  gap: 1rem;
}

.control {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  span {
    flex: 1 1 auto;
  }
  input[type="number"] {
    flex: 0 1 100px;
  }
  input[type="range"] {
    flex: 1 1 100%;
  }
}
</style>
