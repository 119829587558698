<template>
  <div class="select-import">
    <b-img
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/41b259a0d3401a7408428719d04768b16fc3762a9d031c12640aab6c94c8877c?apiKey=d392fee9fbc34ca08c0b35b988671a63"
      fluid
      class="background-image"
      alt="Background"
    />
    <b-container class="grading-options">
      <section>
        <b-row>
          <b-col>
            <h1 class="title">What would you like to do?</h1>
          </b-col>
        </b-row>
        <b-row class="options-container">
          <b-col
            v-if="features.createGrade.includes('upload')"
            md="6"
            class="mb-4"
          >
            <b-card class="option-card">
              <h2 class="option-title">
                <img
                  class="option-icon mr-2"
                  src="../../assets/icons/stars.svg"
                  alt=""
                />
                Upload essays
              </h2>
              <p class="option-description">
                Grade essays in bulk using custom or platform rubrics.
              </p>
              <b-button to="/upload-essays" variant="info">Upload</b-button>
            </b-card>
          </b-col>
          <b-col
            v-if="features.createGrade.includes('google')"
            md="6"
            class="mb-4"
          >
            <b-card class="option-card">
              <h2 class="option-title">
                <img
                  class="option-icon mr-2"
                  src="../../assets/icons/g-classroom.svg"
                  alt="Google Classroom"
                />
                Import from Google Classroom
              </h2>
              <p class="option-description">
                Grade imported assignments using custom or platform rubrics.
              </p>
              <b-button to="/imports/google-classroom" variant="info">
                Import
              </b-button>
            </b-card>
          </b-col>
          <b-col
            v-if="features.createGrade.includes('canvas')"
            md="6"
            class="mb-4"
          >
            <b-card class="option-card">
              <h2 class="option-title">
                <img
                  class="option-icon mr-2"
                  src="../../assets/icons/canvas.svg"
                  alt="Canvas"
                />
                Import from Canvas
              </h2>
              <p class="option-description">
                Grade imported assignments using custom or platform rubrics.
              </p>
              <b-button to="/imports/canvas" variant="info"> Import </b-button>
            </b-card>
          </b-col>
        </b-row>
      </section>

      <CommunityUpdate />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommunityUpdate from "./community_updates/eg3_announcement.vue";

export default {
  name: "GradingOptions",
  components: {
    CommunityUpdate,
  },
  computed: {
    ...mapGetters("config", ["features"]),
  },
};
</script>

<style scoped lang="scss">
.select-import {
  position: relative;
}

.grading-options {
  position: relative;
  min-height: 700px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.title {
  color: #000;
  font: 500 32px/119% Roboto, sans-serif;
  margin-left: 16px;
  position: relative;
}

.options-container {
  position: relative;
  margin: 25px 0 0;
}

.option-card {
  height: 100%;
}

.option-title {
  color: #000;
  font: 500 20px/120% Roboto, sans-serif;
  margin-bottom: 12px;
}

.option-icon {
  width: 32px;
  height: 32px;
}

.option-description {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.option-button {
  font-family: Roboto, sans-serif;
  background-color: #6f42c1;
  border-color: #6f42c1;
}

@media (max-width: 991px) {
  .grading-options {
    padding: 27px 20px;
  }
}
</style>
