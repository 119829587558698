<script>
import EgToggleButton from "@/components/global/eg_toggle_button.vue";

export default {
  name: "FeedbackStep",
  components: { EgToggleButton },
  props: {
    essayContents: String,
    feedback: String,
    errors: String,
  },
  emits: ["reset"],
  data() {
    return {
      registerLink: "https://app.essaygrader.ai/register",
      selectedFeedbackTab: "feedback",
    };
  },
};
</script>

<template>
  <div>
    <div class="step-3-layout mb-3">
      <span class="strong"> Student submission </span>

      <div class="panel">
        <div class="scroll-wrapper">
          <div class="px-1 text-justify preserve-newlines">
            {{ essayContents }}
          </div>
        </div>
      </div>

      <span class="strong"> Reports </span>

      <div class="panel">
        <div class="scroll-wrapper">
          <div class="d-flex flex-gap-1 mb-3">
            <eg-toggle-button
              :pressed="selectedFeedbackTab === 'feedback'"
              @click="selectedFeedbackTab = 'feedback'"
            >
              Feedback report
            </eg-toggle-button>
            <eg-toggle-button
              :pressed="selectedFeedbackTab === 'errors'"
              @click="selectedFeedbackTab = 'errors'"
            >
              Error report
            </eg-toggle-button>
          </div>
          <div
            v-if="selectedFeedbackTab === 'feedback'"
            class="feedback-box"
            v-html="feedback"
          />
          <div
            v-if="selectedFeedbackTab === 'errors'"
            class="feedback-box"
            v-html="errors"
          />
        </div>
      </div>
    </div>

    <div class="grading-buttons-row">
      <b-button class="button large" :href="registerLink" target="_blank">
        Get started for free
      </b-button>
      <b-button class="outline large" @click="$emit('reset')">
        Grade another submission
      </b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.step-3-layout {
  display: grid;

  grid-template: auto minmax(auto, 600px) / 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: 1rem;
}

@media (max-width: 900px) {
  .step-3-layout {
    grid-template: auto minmax(auto, 400px) auto minmax(auto, 400px) / auto;
  }
}

.grading-buttons-row {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  flex-wrap: wrap;
}

.grading-buttons-row > ::v-deep button {
  padding: 7px 14px;
}

.link-button {
  color: #6a50f3;
  &:focus {
    text-decoration: none;
    box-shadow: none;
  }
}

.feedback-box {
  padding: 0;
  border: none;
}

.feedback-box::v-deep {
  p,
  h2,
  h3 {
    margin: 0;
  }

  h3:not(:first-child) {
    margin-top: 15px;
  }

  h2:not(:first-child) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  h3 {
    margin-bottom: 15px;
  }

  h3,
  h2 {
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .criteria-grade {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 20px;
  }
  strong {
    font-weight: 500;
  }
  .ip-suggestion,
  .sp-error,
  .g-error,
  .s-error,
  .c-error,
  .cl-error,
  .l-error,
  .g-suggestion,
  .s-suggestion,
  .c-suggestion,
  .cl-suggestion,
  .l-suggestion,
  .g-impact,
  .s-impact,
  .c-impact,
  .cl-impact,
  .l-impact {
    display: block;
    white-space: normal;
    margin-bottom: 0px;
  }

  .ip-suggestion,
  .g-suggestion,
  .s-suggestion,
  .c-suggestion,
  .cl-suggestion,
  .l-suggestion {
    margin-bottom: 20px;
  }

  h2 {
    border-bottom: 1px solid gray;
  }

  .criteria-suggestion {
    border-radius: 16px;
    background: #fafaff;
    padding: 16px;

    strong {
      color: #6a50f3;
      display: inline;
      align-items: center;

      &:before {
        content: "";
        width: 12px;
        height: 16px;
        margin-right: 5px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2216%22%20viewBox%3D%220%200%2012%2016%22%20fill%3D%22none%22%3E%0A%3Cg%20clip-path%3D%22url(%23clip0_3287_17411)%22%3E%0A%3Cg%20clip-path%3D%22url(%23clip1_3287_17411)%22%3E%0A%3Cpath%20d%3D%22M3.50312%2014.197C3.50312%2014.3938%203.55988%2014.5857%203.66825%2014.7498L4.20388%2015.5526C4.36794%2015.7987%204.74044%2015.9989%205.03638%2015.9989H6.96356C7.2585%2015.9989%207.63106%2015.7988%207.79513%2015.5526L8.32887%2014.7501C8.42069%2014.6114%208.496%2014.3635%208.496%2014.197L8.5%2012.972H3.5L3.50312%2014.197ZM5.98125%200.000514383C2.795%200.01028%200.5%202.59291%200.5%205.47198C0.5%206.85885%201.01375%208.12323%201.86125%209.09073C2.37781%209.67948%203.18438%2010.9104%203.49312%2011.9485C3.4941%2011.9564%203.49606%2011.9647%203.49703%2011.973H8.50328C8.50426%2011.9647%208.50621%2011.9568%208.50719%2011.9485C8.81578%2010.9104%209.6225%209.67948%2010.1391%209.09073C10.9875%208.1501%2011.5%206.8876%2011.5%205.47198C11.5%202.45666%209.02812%20-0.00876374%205.98125%200.000514383ZM6%203.00041C4.62094%203.00041%203.5%204.12198%203.5%205.47229C3.5%205.7751%203.275%206.0001%203%206.0001C2.725%206.0001%202.5%205.7751%202.5%205.5001C2.5%203.5701%204.07031%202.02823%206%202.02823C6.27637%202.02823%206.5%202.25195%206.5%202.52823C6.5%202.80451%206.275%203.00041%206%203.00041Z%22%20fill%3D%22%236A50F3%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22clip0_3287_17411%22%3E%0A%3Crect%20width%3D%2212%22%20height%3D%2216%22%20fill%3D%22white%22%2F%3E%0A%3C%2FclipPath%3E%0A%3CclipPath%20id%3D%22clip1_3287_17411%22%3E%0A%3Crect%20width%3D%2212%22%20height%3D%2216%22%20fill%3D%22white%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E");
        position: relative;
        top: 2px;
      }
    }
  }
}

.preserve-newlines {
  white-space: pre-wrap;
}
</style>
